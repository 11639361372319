<template>
  <div class="disclosure">
    <h1>Harley-Davidson Charging Plan Disclosure</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color">
      <strong>Effective as of September 19th, 2019</strong>
    </p>
    <p>
      By clicking “Agree” below, you agree to receive 500 kilowatt hours (kWh) (or, for charging stations where pricing
      is calculated on a per-minute basis, the per-minute equivalent of 500 kWh) of complimentary charging for your
      newly purchased Harley-Davidson LiveWire (“LiveWire”) on Electrify America’s network of electric vehicle charging
      stations, subject to these Promotion Terms and Conditions. There is no additional fee, purchase, or subscription
      required for your access to this promotion.
    </p>
    <p>
      After signing up, you can redeem this complimentary credit for your LiveWire during your initiation of a charging
      session at any Electrify America charging station, exclusively by using the Electrify America app “Swipe to start”
      or NFC Pass feature with the appropriate plan selected. This complimentary charging may not by initiated using the
      interface screen or buttons on the charger.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <ul>
      <li>
        You may <strong>only</strong> use this complimentary credit for charging the LiveWire, purchased from an
        authorized dealer in the United States, whose VIN you submitted when signing up for this promotion on your
        Electrify America account.
        <strong>No other vehicle is eligible to receive this credit through your account</strong>, whether owned by you
        or others.
      </li>
      <li>
        Complimentary charging time <strong>does not include</strong> any parking or idle fees assessed at the charging
        station.
      </li>
      <li>
        You must follow all product, vehicle, safety, and technical documentation included with your LiveWire when
        charging at an Electrify America charging station.
      </li>
      <li>
        You must use the correct connector type and charge at the correct power level for your LiveWire at the charging
        station.
      </li>
      <li>
        <strong
          >In the event that you return your LiveWire for a refund from its dealer or manufacturer, Electrify America
          reserves the right to charge you retroactively for complimentary charging sessions conducted when the LiveWire
          was in your possession.</strong
        >
        You acknowledge that you forfeit any prior or current credit from this promotion on your account when you return
        your LiveWire, and agree to pay Electrify America for your previously credited charging sessions following your
        return, promptly, upon Electrify America’s request.
      </li>
    </ul>
    <p>
      After you have used all of this promotional credit at Electrify America charging stations, you will not be able to
      reload or add more credit to your promotional plan. In addition, this promotion will automatically end twenty-four
      (24) months after the date of your purchase of your LiveWire, following which you will not be able to access or
      use the remaining kWh credit on your account from this promotion (if any). This credit is not returnable or
      redeemable for any cash value, and may not be sold, loaned, or otherwise distributed to any third party for any
      reason. You may not transfer any portion of the credit to any other Electrify America account.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> and that
      your use of the Electrify America mobile app is subject to the Terms of Use accessible by navigating to the
      <strong>Profile</strong> icon in top right hand corner of map, selecting <strong>“Legal”</strong> and then
      selecting <router-link class="medium" :to="{ name: 'terms-en' }">“Terms of Use”</router-link> &amp;
      <router-link class="medium" :to="{ name: 'privacy-en' }">“Privacy Policy.”</router-link> Please read these Terms
      of Use documents carefully for information about how you can pay through your account for charging session time
      outside of the complimentary 500 kWh (or per-minute equivalent) offered under this promotion.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services, and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, or any
      other contract between you and Electrify America; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      America services, the Electrify America mobile app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this complimentary promotion at any time before its automatic
      termination date for any reason, in its sole discretion, with or without notice to you. Following the termination
      or expiration of this promotion for any reason, your access to the complimentary promotion will immediately cease,
      and you may not use or redeem any remaining portion of the complimentary 500 kWh (or per-minute equivalent). At
      that time, your account will automatically convert to a basic Electrify America “Pass” account, at no additional
      fee or charge to you (neither one-time nor recurring). You may, but are not obligated to, sign up for a different
      type of subscription via the Electrify America mobile app.
    </p>
  </div>
</template>

<script>
export default {
  name: 'harley-davidson-disclosure',
  metaInfo: {
    title: 'Harley Davidson Disclosure | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/harley-davidson-disclosure/' }],
  },
};
</script>
